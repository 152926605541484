div.next-scheduled-session {
  width: 500px;
  border: 10px solid magenta;
  margin: auto;
  padding: 10px;

  th {
    vertical-align: top;
  }
}

div.create-attendee-form {
  width: 500px;
  border: 10px solid magenta;
  margin: auto;
  padding: 10px;
}

h1 {
  text-align: center;
}

.blink {
  animation: 2s linear infinite condemned_blink_effect;
}

@keyframes condemned_blink_effect {
  0% {
    visibility: hidden;
  }
  50% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
